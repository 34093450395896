body.post-type-archive-locations {
  .wrap main.main {
    padding-left: 0;
    padding-right: 0;
  }
  .archive-pagination {
    padding-bottom: 60px;
  }
  .wrap .columns-container.flex-container:last-child,
  .wrap .columns-container.flex-container:only-child {
    padding: 60px 0 30px 0;
  }
}//body.post-type-archive-locations
.locations-list-container {

  article.location-box {
    margin-bottom: 30px;

  }
}
.gm-style-iw {
  h6 {
    color: $brand-primary;
  }
}
.location-info-box {
  h6 {
    @extend .font-heading;
    text-transform: none;
    margin-bottom: 15px;
    font-size: 1.2rem;
  }
  .location-info {
    .loc-address {
      font-size: 1em;
      margin-bottom: 15px;
    }
    .loc-phone,
    .loc-email {
      font-size: 0.9em;

      a {
        color: #000;
        text-decoration: underline;

        &:hover {
          color: $brand-primary;
        }
      }

      span {
        @extend .font-heading;
      }
    }
  }
}

.location-box-inner {
  border: 1px solid $locations-box-border;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 30px 15px;

}


.acf-map-container {
	position: relative;
	text-align: center;
  width: 100%;

	a span {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		background: rgba(60, 102, 101,.8);
		-moz-transition: all 1s;
	    -webkit-transition: all 1s;
	    transition: all 1s;
	    z-index: 1;
	    text-align: center;


	    .btn {
			position: relative;
			top: 50%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			padding: 20px 50px;

	    }
	}
	&:hover a span {
		background: rgba(60, 102, 101,.8);
		opacity: 1;
	}
	.acf-map {
		width: 100%;
		height: 400px;
		border: #ccc solid 1px;
		margin: 0;
	}
}
