body.post-type-archive-careers {
  .wrap.container-fluid>.container {
    padding-top: 0;
    padding-bottom: 0;
  }
}//body.post-type-archive-careers

body.single-careers .wrap.container-fluid>.container {
  // padding-top: 60px;
  padding-bottom: 60px;
}
// For both archive and single:
article.type-careers {
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid $brand-secondary;

  @include media-breakpoint-down(md) {
    margin-bottom: 30px;
    padding-bottom: 30px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 50px;
  }

  header {
    h3.entry-title {
      @extend .font-body;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-size: 1.4em;
      color: $brand-primary;
      line-height: 1.2;
      a {
        color: $brand-primary;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 5px;
      }
    }//h3.entry-title

    .post-date {
      margin-bottom: 15px;
    }//.post-date

  }//header
  .entry-content {
    margin-bottom: 30px;
    line-height: 1.75;

    @include media-breakpoint-down(md) {
      margin-bottom: 10px;
    }

    h6 {
      text-transform: none;
    }

    .qual-beni {
      margin-top: 30px;
    }
  } // .entry-content
  footer {
    border: 1px solid $text-lightbg;
    border-radius: 5px;
    padding: 30px;
    margin-top: 30px;

    .row {
      @include media-breakpoint-up(md) {
        display: flex;
        min-height: 80px;
        padding-top: 15px;
        padding-bottom: 15px;
        justify-content: space-between;
      }


      .col-md {
        margin-bottom: 15px;
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        text-align: center;
        position: relative;

        @include media-breakpoint-up(md) {
          &:after {
            content: "";
            height: 100%;
            width: 1px;
            background: $brand-secondary;
            display: block;
            position: absolute;
            right: 0;
          }
          &:last-child:after {
            display: none;
          }
        }
      }
      .location-col {
        span.loc-title {
          display: block;
        }
        @include media-breakpoint-up(md) {
          flex-wrap: wrap;
          text-align: left;
          justify-content: inherit;
          align-items: center;

          span.loc-title {
            flex-direction: column;
            display: block;
          }
          text-align: left;
        }//media-breakpoint-up(md)

      }//location-col
    }//row
    .cr-phone,
    .cr-fax {
      color: $text-lightbg;

    }
  } //footer
  a.learnmore {
    @extend .font-heading;
    text-transform: uppercase;
    color: $text-lightbg;
  }
  &:last-child {
    border: none;
  }
}//article.type-careers

.apply-btn-container {
  padding: 0 30px;
  @include media-breakpoint-down(md) {
    text-align: center;
    a.btn {
      margin: 0 auto;
    }
  }
}
