.quote-image-container {
  padding-bottom: 41px;
  margin-bottom: 30px;
  @include media-breakpoint-down(md) {
    padding-bottom: 15px;

    .col-md-12 {
      padding: 0;
    }
  }

  .quote-image-inner {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    @include media-breakpoint-down(md) {
      display: block;
      padding: 0;
    }


    &:after {
      content: "";
      width: 100%;
      height: 100%;
      background: lighten($brand-tertiary, 10%);
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      border-radius: 10px;
      @include media-breakpoint-down(md) {
        border-radius: 10px 10px 0 0;
      }
    }
    .fqi-quote {
      color: $text-darkbg;
      width: 50%;
      padding-right: 15px;
      font-style: italic;
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: left;
        padding: 30px;
        font-weight: lighter;
        line-height: 1.9em;
      }

    } //fqi-quote
    .fqi-image {
      margin-top: -8%;
      margin-bottom: -8%;
      img {
        border-radius: 7px;
      }
      @include media-breakpoint-down(md) {
        margin: 0;
        text-align: center;
        img {
          border-radius: 0;
          width: 100%;
          height: auto;
        }
      }
    }

    &.q-and-i {

    }
    &.just-i {

    }
    &.just-q {
      .fqi-quote {
        padding: 30px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }
    }

  } // quote-image-inner
} // quote-image-container
