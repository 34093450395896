.slider-container.flex-container {
  padding-bottom: 0;
  position: relative;
  margin-bottom: 40px;
  padding: 25px 0 25px 0 !important;

  &:before {
    content: "";
    width: 75%;
    height: 100%;
    position: absolute;
    border: 2px solid $brand-primary;
    border-radius: 10px;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
  }

  .slider-inner {
    .carousel {
      .carousel-inner {
        text-align: center;


        .carousel-item-inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // &::-moz-selection { background: transparent; }
          // &::selection { background: transparent; }
          a:active, a:focus {
            outline: none !important;
            box-shadow: none;
          }
        }
      }
    }
  }
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    background-image: none;
    i {
      font-size: 1.5em;
    }
  }
}

.w-32 {
  width: 32% !important;
  height: 201px !important;

  @include media-breakpoint-down(md) {
    height: 135px !important;
  }
}

// modals:
.modal-body {
  text-align: center;

  img {
    max-width: 100%;
    height: auto;
  }
}
.modal-content {
  background: transparent;
  border: 0;

  .close {
    color: $text-darkbg;
    text-shadow: 0 1px 0 $text-lightbg;
  }
}

.modal-lg {
  max-width: 80%;
}
