.container .latest-news-container.flex-container {
  padding: 60px 0;
  h2 {
    margin-bottom: 60px;
    @extend .font-heading;
    font-size: 2.25em;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  .viewall {
    margin: 40px auto 0 auto;
    display: table;
  }

  .row {
    @include media-breakpoint-down(md) {
      display: block;

      .col {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

} // latest-news-container
.news-box {
  .post-image-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    min-height: 311px;
    position: relative;
    border-radius: 5px;

    .post-date {
      background: $brand-primary;
      color: $text-darkbg;
      padding: 20px;
      border-radius: 0 0 5px 0;
    } // post-date
    a {
      .coverall {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        @include transition(all .5s ease);
      }
      &:hover, &:focus {
        .coverall {
          background: rgba($brand-primary, 0.4);
        }
      }
    }
  } // post-image-container
  .post-info-box {
    border: 1px solid $brand-secondary;
    border-top: 0;
    padding: 30px;
    font-size: 0.8em;

    h6 {
      font-size: 1.25rem;
      text-transform: none !important;
      margin-bottom: 20px;
    }
    a, a h6 {
      color: $text-lightbg;
    }
    .desc {
      line-height: 1.75em;
      font-size: 1.1em;
    }
  }
  .readmore {
    color: $text-lightbg;
    margin-top: 15px;
    display: block;
    @extend .font-heading;
    font-size: 1.2em;
  }
} //news-box

body.blog {
  .news-box {
    margin: 30px 0;
    padding-bottom: 30px;
    border-bottom: 1px solid $brand-secondary;
    .post-info-box {
      border: 0;
      padding: 45px 0 0 0;

      h6 {
        @extend .font-body;
        font-weight: 600;
        font-size: 0.9rem;
        text-transform: uppercase !important;
      }
    }
    .readmore {
      @extend .font-body;
      color: darken($brand-secondary, 20%);
      margin-top: 15px;
      display: block;
      text-transform: uppercase;

    } //.readmore
  } // news-box
}//body.blog

body.blog, body.archive {
  .wrap.container-fluid > .container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}


.page-numbers {
  @extend .page-link;
}

.page-item:has( > a.next) {
  background: red;
  color: purple;
  font-size: 3em;
}

.pagination {
  display: flex;
  align-items: center;
  position: relative;

  li {
    a, span {
      color: $pagination-numbers;
      padding: 0;

      &:hover, &:focus {
        background: transparent;
        color: $pagination-numbers;
      }
    }
  }
  .page-numbers {
    padding: 0;
    border: 0;

    i {
      color: $brand-primary;
      font-size: 1.75em;
    }
  }
  .page-item {
    position: relative;
    margin-right: 5px;
    margin-left: 4px;
    margin-bottom: 4px;
    &:first-child {
      margin-left: 0;
    }
    &.first-page-item {
      margin-left: 0;
    }
    &:after {
      content: " ,";
      position: absolute;
      right: -4px;
      bottom: -2px;
      color: $pagination-numbers;
    }
    &.last-page-item {
      &:after {
        content: '\2026';
        position: absolute;
        right: -18px;
        bottom: -2px;
        color: $pagination-numbers;
      }
    }

    &.next-item,
    &.prev-item {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }

  .page-item.active .page-numbers {
    background: transparent;
    color: $pagination-numbers;
    border: 0;

    a, span {
      padding: 0;
    }
  }
  li.next-item {
    position: absolute;
    right: 0;
    padding-left: 15px;
  }
  li.prev-item {
    padding-right: 15px;
  }
}


body.single-post {
  img {
    max-width: 100%;
    height: auto;
  }
  .post-feat-image-container {
    margin-bottom: 40px;
    width: 100%;
    max-width: 100%;
    height: 381px;
    border-radius: 5px;
    @include media-breakpoint-down(md) {
      height: 250px;
    }
  }
  .wrap > .container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  article.post {
    padding-left: 15px;
    padding-right: 15px;
    header {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px solid $brand-secondary;
    }
    h1.entry-title {
      @extend .font-heading;
      color: $brand-primary;
      font-size: 2em;
      text-transform: uppercase;
    }
    time {
      text-transform: uppercase;
      @extend .font-body;
      font-weight: normal;
      font-size: 1.25em;
    }
  }
}
