footer.content-info {
  background: $text-lightbg;
  padding: 40px 30px 0px 30px;

  position: relative;

  @include media-breakpoint-up(md) {
    padding: 150px 0 0 0;
  }


  .gfield_label {
    display: none;
  }
  .btn {

  }
  .gform_title {
    @extend .font-heading;
    color: $text-darkbg;
    font-size: 0.9em;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

}

.footer-top-level {
  .newsletter-container {
    margin-top: 30px;
  }
  @include media-breakpoint-up(md) {
    display: flex;
    // flex-direction: row;
    .newsletter-container {
      -webkit-flex: 1;
      flex: 1;
      margin-top: 0;
    }//.newsletter-container
  }// media up md
}//.footer-top-level

.nav-footer {
  @include media-breakpoint-down(md) {
    // display: none;
    li.menu-pages {
      display: none;
    } // li.menu-pages
    li.menu-item-has-children {
      margin-bottom: 15px;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    }
  } // @media down md
  #menu-footer-nav {
    list-style-type: none;
    li {
      a {
        @extend .font-heading;
        color: $text-darkbg;
        font-size: 1em;
          &:hover, &:focus {
            color: $brand-primary;
          } // a hover (all levels)
      } // a
    } // li (all levels)

    &>li {
      @include media-breakpoint-up(md) {
        margin-right: 90px;
      }

      // &:last-child {
      //   margin-right: 0;
      // } // commented out for space between this item and the form
      &>a {
        @extend .font-heading;
        font-size: 0.9em;
        text-transform: uppercase;
      } // a (top level)
    } // li

    li {
      ul.sub-menu {
        margin-top: 15px;
        list-style-type: none;
        padding-left: 0;
        li {
          margin-bottom: 5px;
          a {
            @extend .font-body;
            color: $text-darkbg;
            font-weight: lighter;
            font-size: 0.9em;
            text-transform: inherit;
            &:hover, &:focus {
              color: $brand-primary;
            } // a hover (all levels)
          }
        }
      } // ul.sub-menu

    } // li
  } // ul#menu-footer-nav
} // nav.nav-footer

.copyright-info {
  background: $text-darkbg;
  color: $text-lightbg;
  padding: 15px 0;
  font-size: 0.7em;
  @include media-breakpoint-down(sm) {
    margin-left: -30px;
    margin-right: -30px;
    padding: 30px 0;
  }
  @include media-breakpoint-down(xs) {
    margin-left: -30px;
    margin-right: -30px;
  }

  a {
    color: $text-lightbg;
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .copyright {
      @include media-breakpoint-down(md) {
        padding: 0 50px;
        text-align: center;
      }
    }
  }
}


.footer-bottom-level {
  border-top: 1px solid $text-darkbg;
  text-align: center;
  padding: 31px 0;
  margin-top: 37px;
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 70px 0;
    margin-top: 85px;
  }

  .brand {
    background: url('../images/huntinsurancereverse.png') no-repeat;
    width: 145px;
    height: 87px;
    margin: 0 auto 22px auto;
    flex-shrink: 0;
    background-size: contain;

    @include media-breakpoint-up(md) {
      width: 145px;
      height: 87px;
      margin: 0;
    }

  }
  .nav-contact-container {
    .nav-contact-container-inner {
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
      }
    .contact-info {
      @include media-breakpoint-down(md) {
        margin-top: 15px;
        font-size: 0.9em;
        justify-content: center;
        a {position: relative;}
        a:not(:last-child) {
          margin-right: 10px;
          padding-right: 10px;
          // border-right: 1px solid $text-darkbg;
          &:after {
            content: "";
            height: 12px;
            width: 1px;
            background: $text-darkbg;
            display: block;
            position: absolute;
            right: 0;
            bottom: 4px;
          } //a:after
        } // a:not:last

      }//media down md
      display: flex;

      @include media-breakpoint-up(md) {
        margin-left: 40px;
      }
      .email, .phone {
        color: $text-darkbg;
        font-size: 0.9em;
        display: block;
      } // email, phone
      .email {

      }
      .phone {

        @include media-breakpoint-up(md) {
          position: relative;
          margin-right: 10px;
          padding-right: 10px;

          &:after {
            content: "";
            height: 12px;
            width: 1px;
            background: $text-darkbg;
            display: block;
            position: absolute;
            right: 0;
            bottom: 4px;
          } // .phone:after
        } // .phone media query md up
      } // .phone
    } // .contact-info
  } // .nav-contact-container-inner
} // .nav-contact-container
} // .footer-bottom-level

.nav-location {

  ul {
    @include media-breakpoint-down(md) {
      justify-content: center;
    }
    li:not(:last-child) {
      margin-right: 10px;
      padding-right: 10px;
      // border-right: 1px solid $text-darkbg;
      &:after {
        content: "";
        height: 12px;
        width: 1px;
        background: $text-darkbg;
        display: block;
        position: absolute;
        right: 0;
        bottom: 4px;
      }
    }
    li {
      display: inline-block;
      position: relative;


      a {
        display: block;
        color: $text-darkbg;
        font-size: 0.9em;
      }
    }
  }
}



.nav-social {
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 70px;
    right: 30px;
    width: 35px;
  }
  li {
    @include media-breakpoint-down(sm) {
      margin-bottom: 10px;
    }
    @include media-breakpoint-up(sm) {
      margin-left: 10px;
      display: flex;
      justify-content: center;
      &:first-child {
        margin-left: 0;
      }
    }



  }
  li a {
    display: block;
    border-radius: 25px;
    width: 35px;
    height: 35px;
    text-indent: -33333px;
  }

  li.menu-facebook a {
    background: $brand-primary url('../images/icon-facebook.png');
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center center;
    border-radius: 25px;
    &:hover, &:focus {
      background: darken($brand-primary, 10%) url('../images/icon-facebook.png');
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  li.menu-instagram a {
    background: $brand-primary url('../images/icon-instagram.png');
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center center;
    border-radius: 25px;
    &:hover, &:focus {
      background: darken($brand-primary, 10%) url('../images/icon-instagram.png');
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  li.menu-twitter a {
    background: $brand-primary url('../images/icon-twitter.png');
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center center;
    border-radius: 25px;
    &:hover, &:focus {
      background: darken($brand-primary, 10%) url('../images/icon-twitter.png');
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  li.menu-linkedin a {
    background: $brand-primary url('../images/icon-linkedin.png');
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center center;
    border-radius: 25px;
    &:hover, &:focus {
      background: darken($brand-primary, 10%) url('../images/icon-linkedin.png');
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  li.menu-youtube a {
    background: $brand-primary url('../images/icon-youtube.png');
    background-repeat: no-repeat;
    background-size: 50% 50%;
    background-position: center center;
    border-radius: 25px;
    &:hover, &:focus {
      background: darken($brand-primary, 10%) url('../images/icon-youtube.png');
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}


footer.quoteform {
  background: $brand-tertiary;
  text-align: center;
  padding: 45px 0;

  .gfield_label {
    display: none;
  }
  ul.gform_fields {
    text-align: center;
    width: 100%;
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    li {
      @include media-breakpoint-up(md) {
        display: inline-block;
        width: auto;
        margin-right: 15px;
        flex: 1;
        &:last-child {
          margin-right: 0;
        }
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    } // li
    li.gfield_html {
      display: none;
    }
  }
  .gform_title {
    color: $brand-primary;
    @extend .font-heading;
    font-size: 1.2em;
    display: block;
    width: 100%;
    margin-bottom: 20px;
    &:after {
      content: "";
      width: 8em;
      display: block;
      position: relative;
      height: 1px;
      margin: 0 auto;
      background: $brand-primary;
      top: 18px;
    }
  }

  .gform_body {
    margin-top: 60px;
  }
  .gform_confirmation_wrapper {
    color: $text-darkbg;
  }


}//.quoteform

footer.quoteform, footer.content-info {

  .gform_footer {
    margin-top: 30px;
  }
}

.gfield input, .gform_fields textarea {
  border: 0;
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: darken($brand-secondary, 30%);
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color: darken($brand-secondary, 30%);
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color: darken($brand-secondary, 30%);
  }
  &:-moz-placeholder { /* Firefox 18- */
    color: darken($brand-secondary, 30%);
  }
}
