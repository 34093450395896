.gform_fields {
  @extend .list-unstyled;
  // @extend .row;

  margin: 0;

  li {
    width: 100%;
  }

  input, select, textarea {
    @extend .form-control;
    @extend .form-control-lg;
    border-radius: 5px;
    font-size: 0.9em;
    // font-family: $font-headings-alt;
    padding: 0.7rem 1rem;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $text-lightbg;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $text-lightbg;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: $text-lightbg;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: $text-lightbg;
    }
  }

  textarea {
    height: auto;
  }

}

.gform_fields select:not([size]):not([multiple]), .input-group-lg>.input-group-btn>select.btn:not([size]):not([multiple]), .input-group-lg>.input-group-btn>select.gform_button:not([size]):not([multiple]), .input-group-lg>select.form-control:not([size]):not([multiple]), .input-group-lg>select.input-group-addon:not([size]):not([multiple]), .search-form .input-group-lg>.input-group-btn>select.search-submit:not([size]):not([multiple]), .search-form .input-group-lg>select.search-field:not([size]):not([multiple]), select.form-control-lg:not([size]):not([multiple]) {
  height: calc(2.7rem + 2px);
}


.gfield_description {
  @extend .alert;
}

.validation_error {
  @extend .alert;
  @extend .alert-danger;
}

.validation_message {
  @extend .alert;
  @extend .alert-success;
}

.gform_button {
  @extend .btn;
  @extend .btn-primary;
  cursor: pointer;
  i:before {
    font-size: 2em;
  }

}

.gfield_required {
  // color: $alert-danger-text;
}

.gform_wrapper ul.gfield_radio li, .gform_wrapper ul.gfield_checkbox li {
  @extend .form-check;
}

.gform_validation_container {
  display: none;

}

.gfield_error .ginput_container {
  margin-bottom: 10px;
}

.form-control:focus {
  border-color: $brand-primary;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($brand-primary, 0.6);
}

.gform_confirmation_message {
  color: $text-darkbg;
  text-align: center;
}
