.btn, a.btn {

  padding: 13px 45px;
  text-transform: uppercase;
  @include transition(all .5s ease);
  background: $brand-primary;
  color: $text-darkbg;
  border: 0;
  font-size: 0.82em;
  cursor: pointer;

  &:hover, &:focus {
    background: darken($brand-primary, 10%);
    color: $text-darkbg;
  }
}
a.btn-arrow, .btn-arrow {
  &:after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: .6em;
    content: "\f144";
    font-family:FontAwesome;
    font-size: 1.1em;

    // border-top: .3em solid transparent;
    // border-bottom: .3em solid transparent;
    // border-left: .3em solid;
  }
}
a.btn-watchvideo {
  font-size: 0.8em !important;
  padding: 10px 50px 10px 40px;
  @include media-breakpoint-down(md) {

  }
}
a.btn-darkbg, .btn-darkbg {
  color: $text-darkbg;
  background: transparent;
  border: 2px solid $text-darkbg;

  &:hover, &:focus {
    background: $text-darkbg;
    color: $brand-primary;

  }
}
a.btn-lightbg, .btn-lightbg {
  color: $text-darkbg;
  background: $text-lightbg;
  border: 0;

  &:hover, &:focus {
    background: $brand-primary;
    color: $text-darkbg;
  }
}

// get rid of bootstrap glow
.btn:focus,.btn:active:focus,.btn.active:focus,
.btn.focus,.btn:active.focus,.btn.active.focus,
button:focus,
.gform_button:focus,.gform_button:active:focus,.gform_button.active:focus,
.gform_button.focus,.gform_button:active.focus,.gform_button.active.focus {
    outline: none;
    box-shadow: none;
}

.btn-primary:disabled {
  background-color: #ccc;
}
