.image-boxes-container {
  padding: 60px 0;

  .row {
    display: flex;
    flex: 1;
  }

  h2.fib-main-title {
    margin-bottom: 40px;
    @extend .font-heading;
    font-size: 2.25rem;

  }
  .fleximgbox-inner {
    border: 1px solid $brand-secondary;
    border-radius: 5px;
    padding: 15px;
    height: 100%;
    display: block;
    color: #000;
    cursor: pointer;

    &:hover {
      color: #000;
      text-decoration: none;
      border-color: darken($brand-secondary, 20%);
    }
  }

  .fleximgbox {
    margin-bottom: 30px;
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 180px;
      .fib-image {
        max-width: calc(100% - 30px);
        max-height: 180px;
        height: auto;
        width: auto;
        margin: 0 15px;
      }//.fib-image
    }//.image-container

    .text-container {
      &:before {
        height: 1px;
        background:  $brand-secondary;
        display: block;
        width: 90%;
        content: "";
        margin: 15px 0 55px 0;
        margin-left: auto;
        margin-right: auto;
      }
      h5.fib-title {
        text-align: center;
        margin-bottom: 30px;
        @extend .font-body;
        text-transform: uppercase;

      }//h5.fib-title
      .fib-desc {
        text-align: center;
        margin-bottom: 30px;
      }//.fib-desc
    }//.text-container
  }//fleximgbox
}//.image-boxes-container
