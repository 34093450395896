.page-header {
  position: relative;
  width: 100%;

  .page-header-inner {
    padding: 240px 0;
    position: relative;

    @include media-breakpoint-down(md) {
      padding: 150px 30px;

      h1 {
        font-size: 1.5em;
      } //h1
    }// media down md

    h1, .header-desc {
      color: $text-darkbg;
    }
    h1 {
      text-transform: uppercase;
      font-size: 3.15rem;
      font-weight: 600;
      font-family: 'Futura PT', sans-serif !important;
      @include media-breakpoint-down(md) {
        font-size: 2.15rem;
      }
    }
    .header-desc {
      font-weight: 300;
    }
    a.btn {
      margin-top: 25px;
      font-size: 0.9em;
    }
  }
  .coverall {
    background: rgba($text-lightbg, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
// .breakout {
//   margin-left: calc(50% - 50vw);
//   margin-right: calc(50% - 50vw);
// }
body.sidebar-primary {
  .page-header {
    // @extend .breakout;
  }
}



// Contact Page:

.contact-info-row {
  margin-top: 90px;
  margin-bottom: 60px;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }

  .col-md {
    @include media-breakpoint-down(md) {
      margin-bottom: 50px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .contact-info-container {
    border: 1px solid #D4D4D4;
    border-radius: 2px;
    padding: 30px 15px;

    @extend .insurance-image-container;
    height: 100%;
    z-index: auto !important;

    .it-icon {
      top: -30px !important;
      height: 60px !important;
      width: 60px !important;
      i.fa {
        &:before {
          color: $text-darkbg;
          font-size: 1.8em;
        }
      }
    }
    .contact-text {
      text-align: center;

    }
    .ci-title {
      @extend .font-heading;
      margin: 45px 15px 30px 15px !important;
      font-size: 1.25em;
    }
    .ci-item {
      margin: 0 15px 30px 15px;
      a {
        color: $text-lightbg;
        font-size: 0.94em;
      }
    }
  }
}
// body.page-template-template-contact {
  label.gfield_label {
    display: none;
  }
  main.main {
  .gfield_description {
    margin: 0 0 5px 0;
    padding: 0;
    font-size: 0.9em;
  }

  .gfield {
    margin: 0 0 30px 0 !important;

    @include media-breakpoint-down(md) {
      margin: 0 0 15px 0 !important;
    }
  }
  @include media-breakpoint-up(md) {
    .gf_right_half, .gf_left_half {
      width: 50% !important;
      input,
      select {
          width: 100% !important;
      }
    }
    .gf_left_half {
      margin-right: 15px;
      padding-right: 7.5px !important;
    }
    .gf_right_half {
      padding-left: 7.5px !important;
    }

    .gf_left_third {
      padding-right: 7.5px !important;
    }
    .gf_middle_third {
      padding-left: 7.5px !important;
      padding-right: 7.5px !important;
    }
    .gf_right_third {
      padding-left: 7.5px !important;
    }
    .gf_left_third, .gf_middle_third, .gf_right_third {
      width: 33.333333%;
      input, select {
        width: 100% !important;
      }
    }
  }//media-breakpoint-up(md)
  .gform_button {
    background: $text-lightbg;
    &:hover, &:focus {
      background: $brand-primary;
    }
  }
  .gform_footer {
    margin: 15px 0;
  }
// }
.gfield.gsection {
  margin: 0 0 5px 0 !important;
}
.gsection_title {
  @extend .font-body;
  text-transform: none;
  font-size: 1.2em;
  margin-bottom: 15px;
}

.ginput_container_fileupload .validation_message {
  background: #FFFFFF;
  border: 0;
}
.gform_fields input, .gform_fields select, .gform_fields textarea {
  border: 1px solid #979797;
}

} // main.main

.columns-container .fc-text ul.gform_fields {
    margin: 0;
    padding: 0;
}
.columns-container .fc-text ul.gform_fields li {
  padding-left: 0;
}


.order-first {
  @include media-breakpoint-down(sm) {
    order: -1;
  }
}

body.search {
  #search + .input-group-btn button[type="submit"] {
    height: 38px;
    font-size: 14px;
    line-height: 1;
  }
}
