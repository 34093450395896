body.post-type-archive-insurance-types {
  article.insurance-types {
    margin: 38px 15px;
    @include media-breakpoint-up(md) {
      margin-bottom: 38px;
      margin-top: 38px;
    }
  }
}
.row-insurance-types-archive {
  margin-bottom: 100px;
}
.insurance-inner {
  position: relative;
  .coverall {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 5px 5px 0 0;
  }
  a:hover {
    text-decoration: none;
    span.cover-image {
      border-radius: 5px 5px 0 0;
      @include grayscale_element(0);

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba($image-darken, 0.3);
        border-radius: 5px 5px 0 0;
        z-index: 1;
      }
    }

  }
}
.insurance-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: -1;
  border-radius: 5px 5px 0 0;
  max-height: 221px;

  span.cover-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    border-radius: 5px 5px 0 0;
    // filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    // filter: gray; /* IE6-9 */
    // -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    @extend .grayscale;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      @include transition(all 200ms ease-in-out);
      background: rgba($image-darken, 0.7);
      border-radius: 5px 5px 0 0;
      z-index: 1;
    }
  }

  h3.entry-title {
    @extend .font-body;
    color: $text-darkbg;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 0;
    padding: 100px 15px;
    display: block;
    z-index: 1;
  }
  .it-icon {
    position: absolute;
    background: $brand-primary;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: -42px;
    height: 85px;
    width: 85px;
    i {
      &:before {
        color: $text-darkbg;
        font-size: 2.5em;
      }
    }
  }
}
.insurance-post-desc {
  border: 1px solid $brand-secondary;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  padding: 30px 15px;

}
span.entry-excerpt {
  display: block;
  color: $text-lightbg;
  text-align: center;
  padding: 15px;
  font-size: 0.95rem;
}


body.single-insurance-types {
  .wrap > .container .main {
    padding: 0 0 35px 0;
  }
  article.insurance-types {
    padding-left: 15px;
    padding-right: 15px;
  }
}
