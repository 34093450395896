.team-archive-container {
  padding: 60px 0;
  @include media-breakpoint-down(md) {
    padding: 60px 30px;
  }
}
.team-subtitle {
  @extend .font-heading;
  color: $text-lightbg;
  text-transform: uppercase;
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
    text-align: center;

  }
}
body.post-type-archive-team {
  .archive-pagination {
    padding-bottom: 60px;
  }
  article.type-team {
    margin-bottom: 40px;

    .team-inner {
      position: relative;

      a span.coverall {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 1;
      }
      a.readfullbio {
        @extend .font-body;
        display: block;
        color: $text-lightbg;
        margin-top: 15px;
        font-weight: 600;
        font-size: 1em;
      } //a.readfullbio

      .team-post-desc {
        border-top: 15px solid $brand-primary;
        text-align: left;

        .entry-excerpt {
          text-align: left;
        }
        .excerpt-content {
          font-size: 0.9em;
          line-height: 1.75em;
        }
        h3.entry-title {
          color: $brand-primary;
          font-size: 1.25em;
          text-transform: none;
          margin-bottom: 0.7rem;
        }
        .job-title {
          @extend .font-body;
          font-size: 0.925em;
          text-transform: none;
          margin-bottom: 15px;
          font-weight: 550;
        } //.job-title

      }// .team-post-desc
    } // .team-inner
  }
}
.team-image {
  min-height: 250px;
}
// Team single:
.single-team-container {
  display: flex;
  position: relative;

  @include media-breakpoint-up(sm) {
    margin-top: 60px;
    padding: 0 15px;

    justify-content: space-between;

    article {
      width: 60%;
    }
    .single-team-image {
      width: 40%;
      position: relative;
    }//single-team-image
  }// media md up

  @include media-breakpoint-down(sm) {
    flex-flow: wrap;
    article {
      padding: 0 30px;
    }
    .single-team-image {
      order: -1;
      height: 300px;
      margin: 0 30px;
      width: 100%;
      border-bottom: 15px solid $brand-primary;
      margin-bottom: 30px;

    }
  }
  .entry-content {
    font-size: 0.9em;
    text-align: left;

    @include media-breakpoint-up(sm) {
      padding-right: 50px;
    }
  }
  .entry-title {
    text-align: left;
    @extend .font-heading;
    color: $brand-primary;
    text-transform: uppercase;
    margin-bottom: 15px;
  }
  .job-title {
    text-align: left;
    @extend .font-body;
    font-size: 1.25em;
    color: $text-lightbg;
    text-transform: none;
    margin-bottom: 15px;
  }
  footer {
    margin-top: 15px;
    margin-bottom: 40px;
    display: block;

    .tm-email,
    .tm-phone {
      color: $text-lightbg;
      display: block;
      font-size: 0.9em;

      &:hover {
        text-decoration: none;
        color: $brand-primary;
      }

      i {
        &:before {
          color: $brand-primary;
          padding-right: 15px;
          display: inline-block;
          font-size: 1.2em;
        }
      }
    }
    .tm-email {}
    .tm-phone {
      margin-bottom: 15px;
    }
  }
}//.single-team-container
