body.post-type-archive-history, .latest-history-container {

  .divider {
    display: none;
  }
  @include media-breakpoint-up(md) {
    .divider {
      height: 1px;
      width: 30px;
      max-width: 55px;
      margin: 26px 0 0 0;
      background: $text-lightbg;
      display: block;

    }
    *[data-name='divider']:nth-of-type(3n+2) {
      display: none;
    }

  }
  .w-100 {
    display: none;
  }
  .w-100:nth-of-type(3n) {
    display: block;
  }


} //body.post-type-archive-history

.body.post-type-archive-history .wrap.container-fluid > .container .main {
  padding: 60px 0 35px 0;
}
.latest-history-container {
  padding: 60px 0;

  h2 {
    margin-bottom: 60px;
  }
}


article.history {
  text-align: center;
  margin-bottom: 50px;
  max-width: 206.67px;


  a {
    @include transition(all .5s ease);

    &:hover, &:focus {
      text-decoration: none;

      .history-date {
        background: $brand-primary;
        color: $text-darkbg;
      }
    }
  }
  .history-date {
    color: $brand-primary;
    border: 2px solid $brand-primary;
    padding: 10px;
    text-align: center;
    display: block;
    border-radius: 5px;
    font-size: 1.5em;
    letter-spacing: 5px;
    margin-bottom: 15px;
  }
  .entry-title {
    color: $text-lightbg;
    font-weight: bold;
  }

}
