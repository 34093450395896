#faq-filter {
  margin-bottom: 30px;
  display: block;
}


article.type-faq,
article.type-post {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $brand-secondary;

  h4 {
    @extend .font-body;
    font-weight: bold;
    font-size: 0.9em;
  } //h4
  .entry-excerpt {
    font-weight: 300;
  }
  .more, .less {
    display: block;
    color: darken($brand-secondary, 20%);
    text-transform: uppercase;
    font-size: 0.9em;
    margin-top: 15px;
  }
} // article.type-faq


.faq-search-form {
  align-items: center;
  margin-bottom: 30px;
  fieldset {
    width: 98%;
  }
  .input-group {
    width: auto;
    #faqsearch {
      border-radius: 5px;
      border-color: #979797;
      font-weight: 300;
      padding-left: 20px;
    }
    .input-group-btn {
      margin-left: 3%;
      button.btn {
        height: 100%;
        font-size: 15px;
        border-radius: 5px;
        padding: 13px 27px;
        cursor: pointer;
      }

    }
  }
}

.faq-category-list {
  margin-bottom: 60px;

  li {
    position: relative;
    padding-right: 12px;
    margin-right: 12px;
    line-height: 15px;
    a {
      font-size: 0.9em;
      color: $pagination-numbers;
      &:hover {
        color: $brand-primary;
        text-decoration: none;
      }
    }
    &:after {
      content: "";
      height: 100%;
      width: 2px;
      background: $locations-box-border;
      position: absolute;
      right: 0px;
      top: -1px;
    }//&:after
    &:last-child {
      &:after {
        display: none;
      }
    }
  }//li
  .list-inline-item:not(:last-child) {
    margin-right: 12px;
  }
}
