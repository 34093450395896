.flex-container.cta-bar-container {
  background: $brand-primary;
  padding: 60px 0;
}
.cta-bar-inner {
  text-align: center;
  color: $text-darkbg;



  .btn {
    margin-top: 30px;
    @include media-breakpoint-up(md) {
      margin-top: 48px;
    }
  }

  &.cta-bar-inner-block {
    .btn {
      margin-top: 40px;
    }

  }
  .row {
    display: flex;
    align-items: center;
    .btn {
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }//include md up
    }//btn
    .ctab-title, .ctab-desc {
      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
    .ctab-desc {
      margin-top: 30px;
      @include media-breakpoint-up(md) {
        margin-top: 15px;
      }
    }
  }//.row
}
.ctab-title {
  @extend .font-heading-light;
  text-transform: none;
  font-size: 1.8em;
  line-height: 1.3em;
  font-weight: 300;
  @include media-breakpoint-down(md) {

  }
}
.ctab-desc {
  font-size: 1.15em;
  line-height: 1em;
  margin-top: 0.75em;
  @include media-breakpoint-down(md) {

  }
}
body.sidebar-primary {
  .cta-bar-container {

  }
}

.full-width-cta.flex-container.cta-bar-container {
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  .ctab-title, .ctab-desc {
    text-align: center;
  }
  .btn {

  }
}
