body {
  // background: $brand-tertiary;
  @extend .font-body;
  font-weight: 300;
}
.wrap.container-fluid {
  padding-left: 0;
  padding-right: 0;

  >.row {
    margin-right: 0px;
    margin-left: 0px;

    >.main {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
body.page-template-template-fullwidth,
body.post-type-archive-careers {
  .container > .row > main.main {
    padding-left: 0px;
    padding-right: 0px;
  }
}
body.search-results {
  .wrap.container-fluid > .container {
    padding-top: 60px;
    padding-bottom: 60px;
    article {
      margin-bottom: 30px;
    }
    .archive-pagination {
      margin: 30px 0;
    }
  }
}



h1, h2, h3, h4, h5, h6 {
  @extend .font-heading;
}
a, a:visited {
  color: $brand-primary;
}
a:hover, a:focus {
  color: darken($brand-primary, 10%);
}

.breakout-container {

  @include media-breakpoint-up(md) {
    margin-right: -15px;
    margin-left: -15px;
  }


  @include media-breakpoint-up(xl) {
    width: 100vw;
    margin: 0 0 0 calc(-50vw + 74%);
    position: relative;
  }
  @include media-breakpoint-down(lg) {
    padding-left: 30px !important;
    padding-right: 30px !important;

    .col-md-8 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }

  }

}//.breakout-container

.breakout-container-grey {
  background: $brand-secondary;
}
// adjust .breakout-container for full width templates:
body.post-type-archive-team,
body.post-type-archive-insurance-types,
body.post-type-archive-locations,
body.single-team,
body.page-template-template-fullwidth {
  .breakout-container {
    @include media-breakpoint-up(md) {
      margin: 0 0 0 calc(-50vw + 50% - 8px);
    }
  }
}

main .flex-container:first-child {
  padding: 0 0 60px 0;
  @include media-breakpoint-down(md) {
    padding: 0 0 30px 0;
  }
}
main .flex-container:last-child {
  padding: 60px 0 0 0;
  @include media-breakpoint-down(md) {
    padding: 30px 0 0 0;
  }
  &.cta-bar-container, &.breakout-container {
    padding: 60px 0 60px 0;
    @include media-breakpoint-down(md) {
      padding: 30px 0 30px 0;
    }
  }
}
main .flex-container:only-child {
  padding: 0;
}

main.main {
  padding: 60px 0;
  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }
}
body.blog main.main {
  padding: 0;
}

body.single-team main.main {
  padding: 0 0 100px 0;
  @include media-breakpoint-down(md) {
    padding: 0 0 50px 0;
  }
}
body.single-post main.main {
  padding: 0;
}

body.page-template-template-newsfooter-php main.main {
  padding: 60px 0 0 0;
  @include media-breakpoint-down(md) {
    padding: 10px 0 0 0;
  }
}
body.insurance-carriers {
  main.main {
    padding: 112px 0;

    @include media-breakpoint-down(md) {
      padding: 50px 0;
    }
  }
}
body.home {
  @include media-breakpoint-down(md) {
    main.main {
      padding: 0 0 50px 0;
    }
  }
}


article {
  ul {
    margin-top: 16px;
    margin-left: 18px;
    padding-left: 0;

    li {
      padding-left: 15px;
      margin-bottom: 7px;
    }
  }
}

// icons not part of icon fonts:
.huntcon-building {
  background: url('../images/huntcon-building.png');
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  background-size: contain;
  background-position: center center;
}
.huntcon-mobile-home {
  background: url('../images/huntcon-mobile-home.png'), no-repeat;
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  background-size: contain;
  background-position: center center;
}
.huntcon-motorcycle {
  background: url('../images/huntcon-motorcycle.png'), no-repeat;
  background-repeat: no-repeat;
  width: 50%;
  height: 50%;
  background-size: contain;
  background-position: center center;
}


@include media-breakpoint-down(md) {
  .flex-container .col-md {
    padding-right: 30px;
    padding-left: 30px;
    text-align: center;
  }
}
.col-md img {
  max-width: 100%;
  height: auto;
}
