// .columns-container.flex-container.breakout-container {
//   padding: 60px 0 60px 0;
// }
.columns-container {
  &.flex-container {
    padding: 60px 0;
    &:first-child {
      padding: 60px 0;
    }
    @include media-breakpoint-down(md) {
      padding: 30px 0 30px 0;

      &:first-child {
        padding: 30px 0;
      }
    }
  }
  .col-heading {
    @extend .font-heading;
    color: $brand-primary;
    text-transform: uppercase;
    margin-bottom: 30px;
    font-size: 2.25rem;
    line-height: 1.3em;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }
  .colsub-heading {
    @extend .font-body;
    line-height: 1.4em;
    text-transform: capitalize;
    margin-bottom: 30px;
    font-weight: 400;

    @include media-breakpoint-down(md) {
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .fc-title {
    text-align: center;
    margin-bottom: 60px;
    margin-top: 0;
    @extend .font-heading;
    font-size: 1.6em;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
      margin-top: 0px;
    }
    span.title-icon {
      background: $text-lightbg;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      margin-right: 15px;
      text-align: center;

      @include media-breakpoint-down(md) {
        margin: 0 auto 30px auto;
        width: 70px;
        height: 70px;
        border-radius: 70px;
      }

      i {
        padding: 5px;
        display: -webkit-inline-flex;
        display: -moz-inline-flex;
        display: -ms-inline-flex;
        display: -o-inline-flex;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        &:before {
          color: $brand-primary;
          font-size: 0.75em;
        }
      }

    }
  }

  .fc-text {
    line-height: 1.75em;
    ul {
      margin-top: 16px;
      margin-left: 18px;
      padding-left: 0;

      @include media-breakpoint-down(md) {
        text-align:left;
      }

      li {
        padding-left: 15px;
        margin-bottom: 7px;
      }
    }
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  .col-md {
    .fcc-title {}
    .fcc-content {}
    margin-bottom: 15px;

    @include media-breakpoint-down(md) {

    }

  }
  .col-md > *:last-child {
    margin-bottom: 0;
  }

  &.w-100 {
    height: 1px;
  }
  @include media-breakpoint-up(md) {
    .col-md {
      margin-bottom: 30px;
    }
    &.w-100 {
      height: 60px;
    }
  } // media up md
} // .columns-container

.video-container {
  .colv-title {
    @extend .font-body;
    font-size: 1.1em;
    text-transform: capitalize;
    color: $brand-primary;
    text-align: right;
    margin: 25px 0 0 0;
  }
  .colv-sub {
    color: $text-lightbg;
    text-align: right;
  }
  .video-inner {
    .embed-responsive-item {}
  }
  .vid-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    cursor: pointer;

    i.fa {
      border: 2px solid $text-darkbg;
      border-radius: 200px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        color: $text-darkbg;
        font-size: 3em;
        padding-left: 10px;
      }
    }
  }
}

.flex-container .col-md:last-child, .columns-container .col-md:last-child {
  margin-bottom: 0;
}
.flex-container.columns-container .col-md a.btn.btn-primary.btn-lightbg {
    margin-top: 30px;
}

// icons
.icon-container {
  background: $text-lightbg;
  border-radius: 104px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 104px;
  margin-bottom: 25px;

  @include media-breakpoint-down(md) {
    margin: 0 auto 25px auto;
  }

  i {
    padding: 5px;
    &:before {
      color: $brand-primary;
      font-size: 2em;
    }
  }
}


// large image
.fc-image-container {
  @include media-breakpoint-up(md) {
    margin-top: -60px;
    margin-bottom: -30px !important;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: -60px !important;
  }
}
