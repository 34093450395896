.brand {
  background: url('../images/logo-horiz.png');
  background-repeat: no-repeat;
  background-position: 0;
  background-size: contain;
  text-indent: -33333px;
  display: block;
  width: 310px;
  height: 76px;
  max-width: 75%;
  flex-shrink: 0;

  @include media-breakpoint-up(md) {
    width: 260px;
    height: 76px;
    flex-shrink: 0;
  }

  @include media-breakpoint-up(xl) {
    flex-shrink: 0;
  }
} // .brand

.navbar-brand {
  margin-right: 1.5rem;
}

header.banner {
  background: $brand-primary;
  padding: 23px 0;
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,1);
  border-color: rgba(0,0,0,0.5);
  border: 2px solid rgba(0,0,0,.5);
  cursor: pointer;

  .navbar-toggler-icon {
    background: transparent;
    align-items: center;
    justify-content: center;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      transform:scale(1.3,1);
      -webkit-transform:scale(1.3,1);
      font-size: 1.2em;
    }
  }
}

nav.nav-primary.navbar-light {
  transition: all 1s ease;

  .container {
    @include media-breakpoint-down(md) {
      padding: 5px 30px;

      .brand {
        // width: 94px;
        // height: 58px;
      } // .brand

    } // @media down md
  } // .container


  .navbar-light .navbar-toggler-icon, .navbar-light .navbar-toggler {
    @include media-breakpoint-down(md) {
      background-color: rgba($text-darkbg,1);
    }
    &:focus {
      cursor: pointer;
    }
  }

  ul {
    li {
      @include media-breakpoint-up(xl) {
        margin-left: 15px;
        margin-right: 15px;
      }

      a.nav-link {
        @extend .font-body;
        color: $text-darkbg;
        text-transform: uppercase;
        font-size: 1em;
        padding: 8px 0;
        letter-spacing: 1px;
        font-weight: 400;
        @include media-breakpoint-up(xl) {
          font-size: 0.85em;
        } //media up xl
      }//a.nav-link
      a.nav-link:hover, a.nav-link:focus, &.active>a.nav-link {
        color: $text-lightbg;
      }
    }// li
  } // ul
} // nav.nav-primary

@include media-breakpoint-down(md) {
  #menu-primary-nav {
    margin: 25px 0;

    li {
      border-bottom: 1px solid darken($brand-primary, 10%);
    }
  }
}
#menu-primary-nav .dropdown-menu {
  top: 94%;

  @include media-breakpoint-down(md) {
    display: block !important;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;

    .dropdown-item, .dropdown-item.active, .dropdown-item:active, .dropdown-item:visited {
      background-color: transparent;
      color: $text-darkbg;
      padding: 8px 20px;
      &:hover {
        color: #000 !important;
      }
      &:last-child {
        border: 0;
      }

    }
  }
}
// primary nav dropdown hover
.dropdown:hover>.dropdown-menu {
  display: block;
}
.dropdown-item, .dropdown-item.active, .dropdown-item:active, .dropdown-item:visited {
  background-color: $text-darkbg;
  color: $text-lightbg;

    &:hover, &:focus {
      color: $text-darkbg;
      background-color: $brand-primary;
    }
}


a.btn-signin {
  padding: 5px 35px;
  font-size: 0.9em;
  @include media-breakpoint-up(lg) {
    margin-right: .5rem;
    margin-left: .5rem;
  }

}
#menu-primary-nav {
  @include media-breakpoint-up(lg) {
    margin-right: 0;
  }
}

#primarynav {
  position: relative;
  justify-content: center;
}

#searchbtn {
  color: $text-darkbg;
  margin: 0 0 0 15px;
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}
.searchformreveal {
  margin-top: 30px;
  @include media-breakpoint-up(md) {
    margin-top: 0;
    display: none;
    position: absolute;
    right: 0;
    top: 50px;
    background: $text-darkbg;
    padding: 5px;
    border-radius: 5px;
    z-index: 1;
  }
  .input-group-btn {
    font-size: 1em;
  }
}

.home-video {
  display: none;
  position: absolute;
  width: 95%;
  top: -148px;
  bottom: 0;
  margin-bottom: 0;
  margin-top: 0;
  background: $text-lightbg;
  @include media-breakpoint-down(md) {
    top: auto;
    position: relative;
    margin-top: 100px;
  }
}
.closevideo {
  position: absolute;
  top: -30px;
  right: 0;
  i.fa:before {
    color: $text-darkbg;
    font-size: 1.2em;
  }
}
