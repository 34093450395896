// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// site colors
$brand-primary: #ffc432;
$brand-secondary: #E9E9E9;
$brand-tertiary: #2C2C2C;
$text-darkbg: #FFFFFF;
$text-lightbg: #000000;
$image-darken: #3E3E3E;
// color codes that are unique to a single element:
$locations-box-border: #D4D4D4; //and faq category list dividing lines
$pagination-numbers: #9B9B9B; //and faq  cateogry list text

// fonts
.font-heading {
  // ex: @extend .font-heading;
  // used on h1 - h6, headings, sub headings
  text-transform: uppercase;
  font-family: 'Futura PT' , sans-serif !important;
  font-style: normal;
  font-weight: 600;
}
.font-body {
  // ex: @extend .font-body;
  // used on h1 - h6, headings, sub headings
  font-family: 'Helvetica Neue', sans-serif !important;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75em
}
.font-bold {
  @extend .font-body;
  font-weight: 700;
}
.font-light {
  @extend .font-body;
  font-weight: 300;
}
.font-heading-light {
  @extend .font-body;
  text-transform: uppercase;
  font-size: 1.4em;
  line-height: 1.1em;
  font-weight: 300;
}

// mixins
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
  // ex: @include transition(color .5s ease);
}
@mixin boxshadow($args) {
  -webkit-box-shadow: $args;
  -moz-box-shadow: $args;
  box-shadow: $args;
}

@mixin grayscale_element($value) {
    -webkit-filter: #{"grayscale(#{$value})"};
    -moz-filter: #{"grayscale(#{$value})"};
    filter:#{"grayscale(#{$value})"};
    // ex: @include grayscale_element(100%);
}
.grayscale {
    @include grayscale_element(100%);
    @include transition(all 200ms ease-in-out);

    &:hover {
        @include grayscale_element(0);
    }
  // ex: @extend .grayscale;
}
