.container {
  > .container,
  .flex-container {
    padding: 0;

    &:not(.columns-container) > .container,
    .flex-container {
      padding: 0;
    }
  }
}

.home .columns-container.flex-container.breakout-container.breakout-container-grey {
  padding: 30px 0 !important;
}

// #gform_3 > div.gform_footer.top_label {
//   margin-bottom: 40px;
// }

body {
  &.page-template,
  &.home,
  &.archive:not(.post-type-archive-locations) {
    main.main {
      @media (min-width: 768px) {
        padding-top: 0;
      }

      .image-boxes-container {
        padding-top: 60px;
      }
    }
  }
}

body.blog .wrap.container-fluid>.container {
  padding-top: 30px;
}

body > div.wrap.container-fluid > div.container > div > main > div.quote-image-container.flex-container {
  padding: 20px 0 0 !important;
  margin-bottom: 10px;
}

#location-show-button {
  margin-bottom: 40px !important;
}

.post-type-archive-faq > div.wrap.container-fluid > div.container {
  padding-bottom: 0 !important;
}

body > div.wrap.container-fluid > div.container > div > main > div > div > div > div.col-md {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
//----------
.faq-category-list {
  .list-inline-item {
    a {
      font: {
        family: 'Helvetica Neue', sans-serif;
        weight: 500;
      }
    }

    &:after {
      width: 1px;
      top: 0;
    }

    &.selected a {
      color: $brand-primary;
    }
  }
}

.posts-pagination {
  .pagination {
    margin-bottom: 0;

    .page-item {
      font: {
        family: 'Helvetica Neue', sans-serif;
        weight: 500;
        font-size: 15px;
      }
    }
  }
}

.contact {
  main .flex-container:last-child {
    padding-top: 30px;
  }
}

.blog .main {
  padding: 0 15px !important;

  @media (max-width: 768px) {
    padding: 30px 0 0 !important;
  }
}

body > div.wrap.container-fluid > div.container > div > main > div.columns-container.flex-container.breakout-container.breakout-container-grey > div > div:nth-child(2) > div > div > div > div:nth-child(2) > hr {
  margin: 40px 0;
}

body > div.wrap.container-fluid > div.container > div > main > div.quote-image-container.flex-container {
  padding-top: 60px;
}

body.single-careers .apply-btn-container {
  padding: 0;
}

.post-type-archive-faq .main {
  padding: 50px 15px;
}

body > div.wrap.container-fluid > div.container > div > main > div > div > div > div > div > p,
body > div.wrap.container-fluid > div.container > div > main > div > div > div > div > h5 {
  text-align: left;
}

#carouselControls > a.carousel-control-next,
#carouselControls > a.carousel-control-prev {
  width: auto;
  margin: 10px;
  opacity: 1;

  i {
    color: $text-lightbg;
    font-size: 25px;
  }
}

// #carouselControls > div > div.carousel-item > div.container {
//   padding: 0 40px;
// }

#location-show-button {
  &:focus {
    background-color: $text-lightbg;
  }
}

.less,
.more {
  display: block;
  text-transform: uppercase;
  color: #9b9b9b;
  margin-top: 15px;
  cursor: pointer;
  font: {
    weight: 500;
    family: 'Helvetica Neue', sans-serif;
    size: 15px;
  }

  &:hover {
    color: $brand-primary;
  }
}

.loc-search-container {
  .loc-input {
    border: 1px solid #979797;
    box-shadow: none;
    border-radius: 5px;
    height: 42px;
    padding: 5px 25px;
    color: #979797;
    font: {
      family: 'Helvetica Neue', sans-serif;
      weight: 300;
      size: 15px;
    }

    &:focus {
      outline: none;
      border-color: $brand-primary;
    }
  }

  .state-prov-input {
    width: 100%;
  }

  .agent-input {
    width: 100%;
  }

  span {
    color: #000;
    margin: auto -15px auto 5%;
    width: 10%;
    font: {
      family: 'Helvetica Neue', sans-serif;
      weight: 300;
      size: 15px;
    }
  }

  .btn {
    margin: 0;
    float: right;
  }

  .btn-go {
    width: 23%;
    padding: 13px 0;
    margin: 0 5% 0 0;
  }

  .btn-search {
    width: 72%;
    padding: 13px 0;
  }
}

body > div.wrap.container-fluid > div > div.container > div.row > main {
  padding-bottom: 0;
}

.acf-map-container .acf-map {
  border: none !important;
}

body.home > div.wrap.container-fluid > div.container > div > main > div:nth-child(4) > div > div:nth-child(2) > div h5,
body.home > div.wrap.container-fluid > div.container > div > main > div:nth-child(4) > div > div:nth-child(2) > div p {
  text-align: left !important;
}

body.home > div.wrap.container-fluid > div.container > div > main > div:nth-child(4) > div > div:nth-child(2) > div h5 {
  margin-bottom: 10px;
}

body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) > div > div > div.col-md > h3,
body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) > div > div > div.col-md > h5 {
  margin-bottom: 15px;
}

@media (max-width:1199px) {
  body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) {
    padding-top: 80px !important;
  }

  body.page-id-4 > div.wrap.container-fluid > div.container > div > main > div.columns-container.flex-container.breakout-container.breakout-container-grey {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }

  body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) > div > div > div.col-md {
    margin-bottom: 30px !important;
  }
}
@media (max-width:991px) {
  body.home > div.wrap.container-fluid > div.container > div > main > div:nth-child(4) > div > div:nth-child(2) > div h5,
  body.home > div.wrap.container-fluid > div.container > div > main > div:nth-child(4) > div > div:nth-child(2) > div p {
    text-align: center !important;
  }

  // .columns-container .col-heading {
  //   font-size: 1.6rem !important;
  //   text-align: left !important;
  // }

  :root body:not(.about):not(.post-type-archive-careers):not(.insurance-carriers):not(.blog) main.main {
    padding: 0 0 50px !important;
  }

  body.post-type-archive-careers main.main {
    padding-bottom: 0 !important;
  }

  .insurance-carriers main.main {
    padding: 50px 0 !important;
  }

  .loc-search-container {
    margin-top: 50px;
  }

  .home {
    .columns-container:first-child {
      .col-heading,
      .colsub-heading,
      .fc-text {
        text-align: left;
      }

      a.btn.btn-primary.btn-lightbg {
        float: left;
      }
    }
  }

  .community-involvement {
    .col-heading,
    .colsub-heading,
    .fc-text {
      text-align: left !important;
    }
  }

  body.single-careers .wrap.container-fluid > .container {
    padding-top: 0 !important;
  }

  .row > div > div.news-box {
    width: 100%;
  }

  .loc-search-container {
    .state-prov-input {
      width: 100%;
    }

    span {
      width: auto;
      margin: 10px 0 -20px 0 !important;
      text-align: center;
      display: block;
    }

    .col-12 {
      &:not(:last-child) {
        margin-bottom: 30px !important;
      }
    }
  }

  body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) > div > div > div.fc-image-container.col-md-6 {
    margin-top: -30px !important;
  }

  body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) {
    padding-top: 60px !important;
  }
}
@media(max-width:768px) {
  body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) {
    padding-top: 30px !important;
  }

  body.home > div.wrap.container-fluid > div.container > div > main > div:nth-child(4) > div > div:nth-child(2) > div:nth-child(1) > div.fc-text > p:nth-child(2) {
    display: none;
  }

  body > div.wrap.container-fluid > div.container > div > main > div:nth-child(4) > div > div:nth-child(2) > div.col-md {
    margin-bottom: 40px;
  }

  body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) > div > div > div.fc-image-container.col-md-6 {
    display: none;
  }

  body.about > div.wrap.container-fluid > div.container > div > main > div:nth-child(1) {
    padding-bottom: 0 !important;
  }
}
@media(max-width:575px) {
  .blog .container {
    padding-top: 0 !important;
  }
}



// 5 years later:
body.about {
  .about-sec-1 {
    .col-heading {
      z-index: 2;
      position: relative;
      font-size: 36px;
      line-height: 1.2;

      @media only screen and (min-width: 768px) {
        width: calc(100% + 150px);
      }
    }

    .colsub-heading {
      line-height: 1.25;
      position: relative;
      z-index: 2;

      @media only screen and (min-width: 768px) {
        width: calc(100% + 80px);
      }
    }

    .fc-image-container {
      margin-top: -10px;
      margin-right: -30px;
    }
  }
}

.affixed-form {
  width: 340px;
  margin-left: 15px;
}

body.contact {
  main.main .gfield_description {
    margin: -8px 0 5px;
    line-height: 1;

    @media only screen and (max-width: 768px) {
      margin: 0 0 5px;
    }
  }
}

body.post-type-archive-locations {
  .locations-content {
    padding: 0 0 30px;

    @media only screen and (max-width: 768px) {
      > .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .loc-search-container {
    padding: 0 15px;

    @media only screen and (min-width: 768px) {
      margin-bottom: 50px;
    }

    @media only screen and (max-width: 768px) {
      padding: 0 !important;
    }
  }

  .acf-map-container {
    position: relative;

    .acf-map {
      transition: opacity 0.1s ease-in-out;

      &.loading {
        opacity: 032;
      }

      .no-matches {
        top: 50%;
        left: 50%;
        font-size: 24px;
        transform: translate(-50%, -50%);
        position: absolute;
        text-align: center;
      }
    }
  }
}

.latest-news-container {
  margin-bottom: 60px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

body:not(.home) .page-header .page-header-inner {
  padding: 120px 0;

  @media only screen and (max-width: 768px) {
    padding: 60px 0;

    h1 {
      font-size: 2rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  header.banner {
    padding: 10px 0;

    nav.navbar {
      padding: 0;

      #menu-primary-nav {
        margin-top: 10px;
      }

      .container {
        padding: 0 15px;

        .brand {
          padding: 0;
          margin-left: -13px;
        }

        #menu-primary-nav {
          margin-bottom: 10px;
        }

        .searchformreveal {
          margin-top: 10px;

          button[type="submit"] {
            background-color: #000;
            margin-left: 10px;
            padding: 12px 30px;
          }
        }
      }
    }
  }

  .page-header .page-header-inner {
    padding: 50px 0;
  }

  body.home {
    .page-header-inner {
      padding: 80px 0;
    }

    .home-video {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 999;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.7);

      .video-inner {
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 30px);
      }

      .closevideo {
        top: 15px;
        right: 15px;
      }
    }
  }

  .carousel {
    display: flex;

    .carousel-item {
      .post-image-container {
        height: 150px;
        min-height: 0;
      }

      .post-info-box {
        padding: 20px 15px;
      }

      .col {
        padding: 0 10px;
      }
    }

    .carousel-control-prev {
      margin-left: 0 !important;
    }

    .carousel-control-next {
      margin-right: 0 !important;
    }
  }

  footer.content-info {
    padding: 40px 0;

    .copyright-info {
      margin: 0;
    }

    .brand.navbar-brand {
      display: none;
    }
  }

  body.single-team {
    .single-team-container {
      .single-team-image {
        margin: 30px 15px;
      }

      article {
        padding: 0 15px;
      }
    }
  }

  .post-type-archive-careers {
    .columns-container.flex-container:first-child {
      padding-top: 50px;

      @media only screen and (max-width: 768px) {
        padding-top: 0;
      }
    }

    article.type-careers {
      padding: 0 15px 30px !important;
    }
  }

  .insurance-carriers {
    main.main {
      padding-top: 50px !important;
    }
  }

  body.post-type-archive-insurance-types article.insurance-types {
    margin: 30px 0;
  }

  .row-insurance-types-archive {
    margin-bottom: 0 !important;
  }

  .faq-category-list {
    margin-bottom: 30px;
  }

  .contact {
    .col-heading {
      text-align: center !important;
    }
  }
}

#primarynav {
  justify-content: flex-end;
}

.brand.navbar-brand {
  margin-left: -14px;
}

.breakout-container {
  @media (min-width: 1200px) {
    margin: 0 0 0 calc(-50vw + 75%);
  }
}

.locations-list-container {
  margin-bottom: 35px;
}

.modal-dialog.modal-lg {
  transform: translate(-50%, -50%) !important;
  left: 50%;
  top: calc(50% - 24px);
  margin: 0;

  .modal-body {
    padding: 0;
  }
}

// .page-header .page-header-inner {
//   @media (max-width: 991px) {
//     h1 {
//       font-size: 1.7rem;
//     }
//
//     .header-desc {
//       font-size: 0.8rem;
//     }
//   }
// }

.image-boxes-container {
  @media only screen and (max-width: 768px) {
    padding: 0 15px !important;
  }

  h2.fib-main-title {
    @media only screen and (max-width: 768px) {
      font-size: 1.5rem !important;
      text-align: center;
    }
  }
}

@media (max-width: 991px) {
  .flex-container .col-md {
    padding-right: 15px;
    padding-left: 15px;
    text-align: left;
  }
}

:root .team-archive-container {
  @media (max-width: 991px) {
    padding: 60px 15px;
  }
}

body:not(.contact):not(.single-post):not(.single-insurance-types):not(.home) main .flex-container:last-child:not(.image-boxes-container) {
  @media (max-width: 991px) {
    padding: 30px 45px 0;
  }
}

@media (max-width: 991px) {
  #carouselControls>a.carousel-control-next {
    margin-right: -20px !important;
  }

  #carouselControls>a.carousel-control-prev {
    margin-left: -20px !important;
  }
}

.news-box .post-image-container {
  @media only screen and (max-width: 768px) {
    min-height: 150px;
  }
}

body.blog .news-box .post-info-box {
  @media only screen and (max-width: 768px) {
    padding: 30px 0 0;
  }
}

body.archive.search article.faq {
  @media only screen and (max-width: 768px) {
    padding: 0 0 30px;
  }
}

.archive-pagination {
  @media only screen and (max-width: 768px) {
    padding: 0 15px;
    margin-bottom: 0 !important;

    .pagination {
      margin-bottom: 0;
    }
  }
}

article.type-post {
  h4 {
    a {
      color: #000;

      &:hover {
        color: $brand-primary;
      }
    }
  }
}

body.post-type-archive-insurance-types article.insurance-types {
  margin: 30px 0;
}

#results.loading {
  opacity: 0.4;
}

.ajax-pagination ul.pagination {
  .page-numbers {
    color: #9b9b9b;
    margin-right: 5px;
    display: inline-block;
  }

  &:not(.not-last-child) {
    .page-numbers {
      &:not(:last-child) {
        &::after {
          content: ",";
        }
      }
    }
  }

  &.not-last-child {
    .page-numbers {
      &:not(:last-child):not(:nth-last-child(2)) {
        &::after {
          content: ",";
        }
      }
    }
  }
}

.single-post {
  article.type-post {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
  }
}

body.error404 {
  main.main {
    padding-bottom: 60px !important;

    button[type="submit"] {
      font-size: 16px;
    }
  }
}

body.search-results {
  .wrap > .container {
    @media only screen and (max-width: 768px) {
      padding-bottom: 0 !important;
    }
  }
}

.page-template-template-contact {
  .gform_wrapper {
    margin-bottom: 60px;
  }
}

.post-type-archive-insurance-types .columns-container.flex-container {
  .col-md,
  .fc-text {
    @media (max-width: 991px) {
      text-align: left;
    }
  }
}

.columns-container .colsub-heading {
  @media (max-width: 991px) {
    text-align: left !important;
  }
}

.post-type-archive-faq {
  .minimize {
    overflow: hidden;
    position: relative;

    &.truncate {
      max-height: 94px;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 5;
        pointer-events: none;
      }
    }
  }
}

.blog .downloads {
  margin-top: 70px;
  padding: 0 15px;

  h3 {
    font-size: 20px;
    margin-bottom: 30px;
    font-weight: bold;
    font-family: "Helvetica Neue", sans-serif;
  }

  .pdfs-wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  a {
    position: relative;
    display: block;
    padding-left: 35px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 10px 20px;
    color: #000;
    flex: calc(50% - 20px);
    max-width: calc(50% - 20px);

    @media (min-width: 768px) {
      flex: calc((100% / 3) - 20px);
      max-width: calc((100% / 3) - 20px);
    }

    &::before {
      content: url("../images/download.svg");
      width: 20px;
      height: auto;
      position: absolute;
      left: 0;
      top: 5px;
    }
  }
}

.single-insurance-types {
  .columns-container .fc-text p:last-child {
    margin-bottom: 1rem;
  }
}
