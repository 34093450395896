aside.sidebar {

}


.sidebar-primary .main {
  @include media-breakpoint-between(sm, lg) {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  @include media-breakpoint-up(md) {

  }
}

.page-header-inner .col-md-4 {
  position: absolute;
  right: 0;
  top: 0;
  .affixed-form {
    position: absolute;
    top: 80px;
  }
}

.page-header-inner .col-md-4.sidebar-scroll {
  position: absolute;
  right: 0;
  top: 0;

  .affixed-form {
    position: fixed;
    margin-top: -60px;
    top: auto;
  }
}
.page-header-inner .col-md-4.sidebar-stop {
  .affixed-form {
    position: absolute;
    bottom: 30px;
    top: auto;
  }
}

.affixed-form {
  // position: sticky;
  @include transition(all .7s ease);


  // @include media-breakpoint-up(lg) {
  display: block;
  width: 210px;
  @include boxshadow(0 7px 15px 1px rgba(0,0,0,.20));
  z-index: 99;
  width: 260px;
  margin-left: 60px;

  background: lighten($brand-secondary, 2%);
  border-radius: 5px;
  border: 2px solid $brand-secondary;

  .gform_heading {
    background: $text-darkbg;
    border-bottom: 4px solid darken($brand-secondary, 1%);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .gform_confirmation_wrapper {
    padding: 15px;
  }

  .gform_title {
    color: $brand-primary;
    @extend .font-heading;
    font-size: 1.15em;
    display: block;
    width: 100%;
    margin-bottom: 27px;
    margin-top: 15px;
    &:after {
      content: "";
      width: 50%;
      display: block;
      position: relative;
      height: 2px;
      margin: 0 auto;
      background: $brand-primary;
      top: 15px;
    }
  }
  .gform_description {
    display: block;
    text-align: center;
  }
  .sidebar-phone {
    display: block;
    text-align: center;
    color: $text-lightbg;
    @extend .font-heading;
  }
  .gform_body, .gform_footer, .gform_heading {
    text-align: center;
  }
  .gform_body {
    padding: 30px 30px 0 30px;
  }
  .gform_footer {
    padding: 0 30px 0 30px;
  }
  .gform_heading {
    padding: 15px 12px;
    font-size: 0.9em;
  }
  .gfield_label {
    display: none;
  }
  .gform_fields {
    li:not(:first-child) {
      margin-bottom: 15px;
    }
  }
  .gfield input {
    border: 0;
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: darken($brand-secondary, 30%);
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: darken($brand-secondary, 30%);
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: darken($brand-secondary, 30%);
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: darken($brand-secondary, 30%);
    }
  }
  .gform_button {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }

} //.affixed-form
